import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//Importing Views
import PaymentComponent from './PaymentWidget/PaymentComponent';

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <PaymentComponent
            keys={{
              stripe:
                'pk_live_51KMcAkJWs6x80GMv3EweIU4XV5FNtuLswFAQ94O81Q9psRnni7OkTfCpPHZqAEl71l43NHwki9vg37UUxcZwBOol00vWwSS9Fc',
            }}
          />
        </Route>
        <Route>
          <PaymentComponent
            keys={{
              stripe:
                'pk_live_51KMcAkJWs6x80GMv3EweIU4XV5FNtuLswFAQ94O81Q9psRnni7OkTfCpPHZqAEl71l43NHwki9vg37UUxcZwBOol00vWwSS9Fc',
            }}
          />
        </Route>
      </Switch>
    </Router>
  );
}
