import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import CreditCardForm from "./CreditCardForm";
import "./PaymentComponent.css";
import "./elements.css";
import { loadStripe } from "@stripe/stripe-js";
import {TiTick} from "react-icons/ti"


//config of fonts for the stripe prebuilt elements
const ELEMENTS_OPTIONS = {
    fonts: [
        {
            cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
        },
    ],
};

export default function PaymentComponent(props) {
    return (
    <main id="wrapper" style={{width:"100%",minHeight:"100vh"}}>
    <div className="w-full flex flex-col items-center justify-center my-10 p-4">
        <div className="flex flex-col items-center justify-center mb-6">
            <img src={`${window.location.origin}/images/logofor21sagestudios.jpg`} alt="" 
            className="rounded-full h-32 w-32 mb-2"/>
            <h5 className="text-3xl font-medium text-center">21 Sage Studios</h5>
            <div className="w-32 border-b border-2 border-gray-300 my-4"></div>
            <h5 style={{fontSize:"16px"}} className="text-blue-800 text-center font-semibold flex items-center">Powered by Stripe <TiTick style={{fontSize:"3rem"}}/></h5>
        </div>

               {/* Elements Wrapper and checkout form component */}
               <Elements
                   stripe={loadStripe(props.keys.stripe)}
                   options={ELEMENTS_OPTIONS}
               >
                   <CreditCardForm />
               </Elements>
    </div>
    
    <div id="wave">
    </div>
    </main>
        
    );
}